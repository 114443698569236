'use client';

import React, { useEffect } from 'react';
import clsx from 'clsx';
import { CommonDI, LoggerType } from '@headless-workspace/domain/common/DI';
import { useClientErrorStrategy } from '@headless-workspace/domain/common/client';
import { GlowButton, GlowTitle } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { ErrorPageProps } from './types';

type ErrorPageContentProps = Readonly<{
    title: string;
    actionLabel: string;
    fallbackUrl?: string;
}> &
    ErrorPageProps;

const DIGEST_MESSAGE = 'Digest error ID :';

export const ErrorPageContent: Component<ErrorPageContentProps> = ({ title, actionLabel, error, fallbackUrl }) => {
    const { errorStrategy, shouldRecover, canRetry, onFallback } = useClientErrorStrategy(
        CommonDI.get(LoggerType),
        fallbackUrl,
    );

    useEffect(() => {
        errorStrategy.current?.onError(error, { shouldRecover, canRetry, onFallback });
    }, [errorStrategy, error, canRetry, shouldRecover, onFallback]);

    // If the error is recoverable, we don't want to show the error page
    return shouldRecover() ? null : (
        <div className={'flex justify-center items-center text-center h-fullViewport bg-background-neutral'}>
            <section
                className={clsx(
                    'flex flex-col justify-center gap-2 max-w-[80%] tablet:max-w-[50%]',
                    'p-2 border-medium border-solid rounded-0.5 border-[#D7D7D7] bg-background-l3',
                )}
            >
                <GlowTitle text={title} />

                <GlowTitle fontSize={'title2'} text={error.message} />
                <GlowTitle fontSize={'title3'} text={`${DIGEST_MESSAGE} ${error.digest}`} />

                <GlowButton onClick={window.location.reload} label={actionLabel} variant={'secondary'} />
            </section>
        </div>
    );
};
