'use client';

import React, { useEffect, useState } from 'react';
import { buildRoute, Locale, SUPPORTED_LOCALES } from '@headless-workspace/config';
import { messages } from '@headless-workspace/domain/common/client';
import { LanguageHelpers } from '@headless-workspace/utils';
import { ErrorPageContent } from './[locale]/errors/ErrorPageContent';
import { ErrorPageProps } from './[locale]/errors/types';

const DEFAULT_ERROR_TITLE = 'An error occurred';
const DEFAULT_ERROR_ACTION_LABEL = 'Try again';

export default function GlobalError(props: ErrorPageProps) {
    const [locale, setLocale] = useState<Locale>(SUPPORTED_LOCALES.DEFAULT);
    const [title, setTitle] = useState<string>(DEFAULT_ERROR_TITLE);
    const [actionLabel, setActionLabel] = useState<string>(DEFAULT_ERROR_ACTION_LABEL);
    const [fallbackUrl, setFallbackUrl] = useState<string | undefined>();

    useEffect(() => {
        const browserLocale: Locale = (navigator.language as Locale) ?? SUPPORTED_LOCALES.DEFAULT;
        setLocale(browserLocale);

        const localizedMessages = messages[browserLocale] ?? messages[SUPPORTED_LOCALES.DEFAULT];
        setTitle(localizedMessages.Common.server.error.title);
        setActionLabel(localizedMessages.Common.server.error.tryAgain);
        setFallbackUrl(buildRoute(browserLocale, 'help'));
    }, []);

    return (
        <html lang={LanguageHelpers.parseLanguage(locale)}>
            <body>
                <ErrorPageContent {...props} title={title} actionLabel={actionLabel} fallbackUrl={fallbackUrl} />
            </body>
        </html>
    );
}
